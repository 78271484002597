@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Roboto", sans-serif;
}

@media screen and (min-width: 480px) {
  .card:nth-child(7n + 1) {
    grid-column: auto/span 2;
    grid-row: auto/span 2;
  }
}

.prompt::-webkit-scrollbar {
  width: 5px;
}

.prompt::-webkit-scrollbar-thumb {
  background-color: #666e75;
  border-radius: 5px;
}
